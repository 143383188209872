body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background: #f4f4f9;
  color: #333;
}

.app {
  text-align: center;
  padding: 20px;
}

.controls {
  margin-bottom: 20px;
}

.controls input {
  padding: 10px;
  font-size: 16px;
  width: 200px;
  margin-right: 10px;
}

.controls button {
  padding: 10px 20px;
  font-size: 16px;
  margin: 0 5px;
  cursor: pointer;
}

.tree {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.node {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #ddd;
  font-size: 20px;
  font-weight: bold;
}
